/* =============================================================================
   navigation.scss - Navigation Styles
   ========================================================================== */

.navigation {
	display: none;
}

ul.tabs {
	position: relative;
	list-style: none;
	width: 100%;
	margin-bottom: 4rem;
	li {
		border-left: 2px solid $third-color;
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 2rem;
		padding-left: 1.2rem;
		@include transition(all 300ms);
		a {
			text-decoration: none;
			font-family: $font-headline;
			font-size: 1.7rem;
			line-height: 2rem;
			padding-bottom: 0.8rem;
			color: $primary-color;
			opacity: 0.5;
		}
		&:after {
			content: "";
			position: absolute;
			width: 2px;
			height: 2.2rem;
			background: $third-color;
			left: -2px;
			top: 1.9rem;
		}
		&:hover {
			border-left: 2px solid $secondary-color;
			a {
				color: $primary-color;
				opacity: 1;
			}
		}
		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}
}

.menu-toggle {
	position: relative;
	height: 1.6rem;
	width: 2.1rem;
	margin-right: 1rem;
	margin-top: 1.2rem;
	z-index: 1002;
	cursor: pointer;
	span {
		background: $primary-color;
		display: block;
		height: 2px;
		margin: 0 0 0.5rem 0;
		@include border-radius(5px);
		@include transition(background 300ms);
	}
	&:hover {
		span {
			background: $secondary-color;
		}
	}
}

@media (#{$bp-larger-than-tablet}) {
	.navigation {
		display: block;
	}
	.menu-toggle {
		display: none;
	}
}

@media (#{$bp-larger-than-tablet}) {
	
	nav ul {
		list-style: none;
		margin: 0;
		padding: 0;
		background: $primary-color;
		padding: 9.6rem 0 0 4rem;
		li {
			position: relative;
			display: block;
			width: 100%;
			margin-bottom: 1.6rem;
			a {
				font-family: $font-headline;
				color: $white;
				opacity: 0.5;
				text-decoration: none;
			}
			&:hover {
				a {
					color: $white;
					opacity: 1;
				}
			}
		}
	}

	ul.tabs {
		display: inline-block;
		min-height: 3rem;
		li {
			border-bottom: 2px solid $third-color;
			border-left: none;
			position: relative;
			width: auto;
			float: left;
			margin-right: 1.6rem;
			margin-bottom: 1rem;
			padding-bottom: 0.6rem;
			padding-left: 0;

			&:after {
				width: 1.6rem;
				height: 2px;
				bottom: -2px;
				right: -1.6rem;
				left: auto;
				top: auto;
			}
			&:hover {
				border-bottom: 2px solid $secondary-color;
				border-left: none;
			}
			&:last-of-type {
				padding-right: 0;
				&:after {
					display: none;
				}
			}
		}
	}
}