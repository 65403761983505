/* =============================================================================
   buttons.scss - Buttons Classes
   ========================================================================== */

.button,
button {
  display: inline-block;
  height: 42px;
  min-width: 200px;
  padding: 0 30px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  border: 0;
  background: $white;
  @include box-shadow;
  @include transition(all 300ms);
  &:hover,
  &:focus {
    color: $secondary-color;
    @include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
    @include transition(all 300ms);
    outline: 0;
  }
}

input {
  &[type="submit"],
  &[type="reset"],
  &[type="button"] {
    display: inline-block;
    height: 42px;
    min-width: 200px;
    padding: 0 30px;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    box-sizing: border-box;
    border: 0;
    background: $white;
    @include box-shadow;
    @include transition(all 300ms);
    &:hover,
    &:focus {
      color: $secondary-color;
      @include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
      @include transition(all 300ms);
      outline: 0;
    }
  }
}

.button.button-primary,
button.button-primary {
  color: $secondary-color;
  background-color: $white;
  border-color: $white;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $secondary-color;
  }
}

.button.button-secondary,
button.button-secondary {
  color: $third-color;
  background-color: $white;
  border-color: $white;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $third-color;
  }
}


input {
  &[type="submit"].button-primary,
  &[type="reset"].button-primary,
  &[type="button"].button-primary {
    color: $secondary-color;
    background-color: $white;
    border-color: $white;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $secondary-color;
    }
  }
}

input {
  &[type="submit"].button-secondary,
  &[type="reset"].button-secondary,
  &[type="button"].button-secondary {
    color: $third-color;
    background-color: $white;
    border-color: $white;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $third-color;
    }
  }
}

input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    height: 38px;
    padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: #fff;
    border: 1px solid lighten($border-color, 8.8%);
    box-shadow: none;
    box-sizing: border-box;
  }
}

@media (#{$bp-larger-than-desktop}) {
  .button,
  button {
    font-size: 1.4rem;
  }
  input {
    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
      font-size: 1.4rem;
    }
  }
}