/* =============================================================================
   spacing.scss - Spacing Classes
   ========================================================================== */

input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}

button,
.button,
pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 1rem;
}
