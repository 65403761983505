/* =============================================================================
   helpers.scss - Helper Classes
   ========================================================================== */

.clear::after, .clearfix::after {
  content: "";
  display: table;
  clear: both; 
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; 
}

.pull-left {
  float: left !important; 
}

.pull-right {
  float: right !important; 
}

.pull-none {
  float: none !important; 
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
	opacity: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; 
	@include transition(opacity 200ms);
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	background: rgba(pink, 0.75);
	color: white;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 1.2;
  width: auto;
  height: auto;
  margin: 0;
	opacity: 1;
  overflow: visible;
  clip: auto; 
	z-index: 9999;
}

.invisible {
  visibility: hidden !important; 
}

.overflow-hidden {
	overflow: hidden;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

.text-hide {
  font: "0/0" a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; 
}

.text-justify {
  text-align: justify !important; 
}

.text-nowrap {
  white-space: nowrap !important; 
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.text-left {
  text-align: left !important; 
}

.text-right {
  text-align: right !important; 
}

.text-center {
  text-align: center !important; 
}

.text-lowercase {
  text-transform: lowercase !important; 
}

.text-uppercase {
  text-transform: uppercase !important; 
}

.text-capitalize {
  text-transform: capitalize !important; 
}

.text-no-decoration {
  text-decoration: none !important;
}

.font-weight-normal {
  font-weight: 400; 
}

.font-weight-bold {
  font-weight: 700; 
}

.font-italic {
  font-style: italic;
}

.no-padding {
  padding: 0 !important;
}

.top40 {
  margin-top: 4rem;
}

.no-align {
  padding: 0 !important;
  margin: 0 !important;
}

.padding25 {
  padding: 2.5rem !important;
}

.full-width {
  width: 100%;
  box-sizing: border-box;
}

.max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}
