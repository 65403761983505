/* =============================================================================
   lists.scss - Lists Classes
   ========================================================================== */

  ul {
  list-style: circle;
  padding: 0 0 0 4rem;
  margin: 0;
  &.reset {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 0;
    }
  }
  li {
    margin-bottom: 1rem;
  }
  ul, ol {
    margin: 1rem 0 1rem 3rem;
    list-style: circle inside;
  }
}

ol {
  list-style: decimal inside;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 1rem;
  }
  ul, ol {
    margin: 1rem 0 1rem 3rem;
    list-style: circle inside;
  }
}

ul, ol {
  font-size: 1.3rem;
  ul, ol {
    margin: 1rem 0 1rem 3rem;
  }
}

ul.list {
  list-style: none;
  padding: 0;
  li {
    position: relative;
    font-size: 1.3rem;
    border-bottom: 1px solid $third-color;
    padding-bottom: 0.6rem;
    margin-bottom: 1rem;
    a {
      position: relative;
      display: block;
      text-decoration: none;
      z-index: 1;
      &:after {
        position: absolute;
        content: "\f105";
        display: block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 1.4rem;
        font-weight: bold;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        top: 3px;
        right: 0;
        color: $primary-color;
        @include transition(all 300ms);
      }
      &:hover {
        &:after {
          right: 7px;
        }
      }
    }
    ul {
      display: none !important;
    }
  }
}

ul.pagination {
  list-style: none;
  margin: 0;
  padding: 3.5rem 0 1rem 0;
  text-align: center;
  li {
    margin: 0 0.3rem;
    display: inline;
    font-weight: bold;
    font-size: 1.8rem;
    a {
      text-decoration: none;
      font-weight: 300;
      font-size: 1.8rem;
    }
    &:first-of-type,
    &:last-of-type {
      margin: 0 0.7rem;
      a {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
  }
}

@media (#{$bp-larger-than-desktop}) {
  ul, ol {
    font-size: 1.6rem;
  }
  ul.list li {
    font-size: 1.6rem;
  }
}