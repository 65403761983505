/* ==========================================================================
   news.scss - News Styles
   ========================================================================== */

$el : "block";

.articles-list {
    div > a {
        display: block;
    }
    .#{$el} {
        &-articles {
            &-teaser {
                margin-bottom: 3rem;
                padding: 0;
                &-text {
                    position: relative;
                    height: 12rem;
                    padding-bottom: 1rem;
                    overflow: hidden;

                    @media (#{$bp-larger-than-phablet}) {
                        height: 16rem;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 5rem;
                        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+85&0+0,1+100 */
                        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

                        @media (#{$bp-larger-than-phablet}) {
                            height: 6rem;
                        }
                    }
                }
            }
        }
        &-questions {
            &-item {
                padding-bottom: 1rem;
                margin-bottom: 3rem;
                &-text {
                    position: relative;
                    height: 10rem;
                    padding-bottom: 0;
                    overflow: hidden;

                    @media (#{$bp-larger-than-phablet}) {
                        height: 12rem;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 4rem;
                        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+85&0+0,1+100 */
                        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 51%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 51%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.85) 51%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

                        @media (#{$bp-larger-than-phablet}) {
                            height: 5rem;
                        }
                    }
                }
            }
        }
    }

    &.drug-newslist,
    &.ask-newslist {
        position: relative;
        display: inline-block;
        margin-bottom: 5rem;
        overflow: hidden;
        .show-btn-ask,
        .show-btn-news {
            position: absolute;
            bottom: 0px;
            left: 0;
            background: $white;
            width: 100%;
            display: none;
        }
        &.closed {
            .show-btn-ask,
            .show-btn-news {
                display: block;
            }
        }
        &.opened {
            .show-btn-ask,
            .show-btn-news {
                display: block;
            }
        }
    }

    @media (#{$bp-larger-than-phablet}) {

        margin: 0 -2%;
        .four.columns {
            margin-left: 2%;
            width: 30.66667%;
        }
        .six.columns {
            margin-left: 2%;
            width: 46%;
        }
        &.block-centers {
            margin: 0 -2%;
            .six.columns {
                margin-left: 2%;
                width: 47%;
            }
        }
        &.block-slang {
            .six.columns {
                width: 100%;
                margin: 0;
                padding: 0 2%;
            } 
        }
    }

    @media (#{$bp-larger-than-desktop}) {
        &.block-slang {
            margin: 0 -2% 5rem -2%;
            .six.columns {
                width: 47%;
                margin-left: 2%;
                padding: 0;
                width: 47%;
            }
            .block-articles-teaser {
				min-height: 100px;
            }
        }
    }
}

body.home {
    .articles-list {
        .#{$el} {
            &-questions {
                &-item {
                    &-text {
                        height: 15rem;
                        @media (#{$bp-larger-than-phablet}) {
                            height: 20rem;
                        }
                    }
                }
            }
        }
    }
}

.box_rate {
    @include border-radius(4px);
    margin-bottom: 2rem;
    background: $white;
    .title {
        position: relative;
        padding: 1.5rem 0;
        text-transform: uppercase;
        border-bottom: 1px solid $light-grey;
    }
    .content {
        padding: 1.5rem 0;
        .stars {
            font-size: 3rem;
            line-height: 3rem;
            float: left;
            a {
                text-decoration: none;
            }
            span {
                margin: 0;
                padding: 0;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $third-color;
            }
            .star {
                &.hovered {
                    span {
                        color: $third-color;
                    }
                }
            }
        }
        .rate {
            margin-top: 0.5rem;
            float: right;
            font-size: 1.3rem;
            mark {
                color: $secondary-color;
                background: $white;
                font-weight: 600;
            }
        }
    }    
}

.box_share {
    margin-bottom: 3rem;
    ul {
        li {
            display: inline-block;
            margin-right: 1rem;
            a {
                text-decoration: none;
            }
        }
    }
    .mail-send {
        width: 24rem;
        margin-top: 2.5rem;
        &-close {
            text-decoration: none;
            font-size: 2.5rem;
            bottom: 1.5rem;
            float: right;
            position: relative;
        }
    }
    .error {
        margin-bottom: 2rem;
        color: rgb(184, 29, 50);
    }
}
