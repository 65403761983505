/* =============================================================================
   functions.scss - Functions Classes
   ========================================================================== */

@function grid-column-width($n) {
  @return $column-width * $n - ($column-margin*($total-columns - $n)/$total-columns);
}

@function grid-offset-length($n) {
  @return grid-column-width($n) + $column-margin;
}

// Calc PX to EM
@function em($px, $base: $font-size-base) {
  @return ($px / $base) * 1em;
}

// Calc PX to REM
@function rem($px, $base: $font-size-base) {
  @return ($px / $base) * 1rem;
}

// Border Radius
@mixin border-radius($radius: 5px) {
border-radius: $radius;
-webkit-border-radius: $radius;
-moz-border-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow: 0 2px 6px 0 rgba(0,0,0,0.16)) {
box-shadow: $shadow;
-webkit-box-shadow: $shadow;
-moz-box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
transition: $transition;
-webkit-transition: $transition;
-moz-transition: $transition;
-ms-transition: $transition;
-o-transition: $transition;
}

// Transform
@mixin transform($transformation) {
transform: $transformation;
-webkit-transform: $transformation;
-moz-transform: $transformation;
-ms-transform: $transformation;
-o-transform: $transformation;

-webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
backface-visibility: hidden;
}

// box-sizing: border-box
@mixin border-box() {
box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
}

// linear gradient
@mixin gradient($bottomColor, $topColor) {
background-color: $topColor;
background-image: linear-gradient(bottom, $bottomColor 0%, $topColor 100%);
background-image: -o-linear-gradient(bottom, $bottomColor 0%, $topColor 100%);
background-image: -moz-linear-gradient(bottom, $bottomColor 0%, $topColor 100%);
background-image: -webkit-linear-gradient(bottom, $bottomColor 0%, $topColor 100%);
background-image: -ms-linear-gradient(bottom, $bottomColor 0%, $topColor 100%);

background-image: -webkit-gradient(
  linear,
  left bottom,
  left top,
  color-stop(0, $bottomColor),
  color-stop(1, $topColor)
  );
}

@mixin outter-border($color, $padding) {
z-index: 2;
&:before {
  border: 1px solid $color;
  bottom: -$padding;
  content: '';
  left: -$padding;
  position: absolute;
  right: -$padding;
  top: -$padding;
  z-index: -1;
  @include transition(border 200ms);
}
}

@mixin triangle($dir, $size, $color) {
border: $size transparent solid;

@if ($dir == "top" or $dir == "up") {
  border-bottom-color: $color;
  border-width: 0 $size/2 $size $size/2;
}
@else if ($dir == "right" or $dir == "after") {
  border-left-color: $color;
  border-width: $size/2 0 $size/2 $size;
}
@else if ($dir == "bottom" or $dir == "down") {
  border-top-color: $color;
  border-width: $size $size/2 0 $size/2;
}
@else if ($dir == "left" or $dir == "before") {
  border-right-color: $color;
  border-width: $size/2 $size $size/2 0;
}
}


/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
$shadow: ();
$from: $stroke*-1;
@for $i from $from through $stroke {
 @for $j from $from through $stroke {
    $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
  }
}
@return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
text-shadow: stroke($stroke, $color);
}