// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements
// are based on 10px sizing. So basically 1.5rem = 15px :)

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 400;
  font-family: $font-family;
  color: $font-color;
  background: $primary-color;
  @media (#{$bp-larger-than-desktop}) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  .main-content {
    background: $white;
  }
}

.nine.columns {
  @include box-shadow(inset 5px 0px 10px 0px rgba(0, 0, 0, 0.18));
}

.main-title {
  position: absolute;
  display: block;
  top: 4rem;
  left: 4rem;
  font-family: 'Am Sans';
  font-size: 2rem;
  color: $secondary-color;
  text-transform: uppercase;
  span {
      color: $third-color;
  }
  a {
      text-decoration: none;
      color: $secondary-color;
      span {
          color: $third-color;
      }
  }
}

img {
  &.obrazek,
  &.news3-article-image {
    margin-bottom: 3rem;
    max-width: 100%;
    height: auto;
  }
}
p img {
  margin-bottom: 3rem;
  max-width: 100%;
  height: auto;
}

.block-text-inner {
  h2 {
    font-weight: bold;
  }
}

.langflag {
  position: relative;
  display: block;
  width: 4rem;
  left: 4rem;
  margin-top: 4rem;
  img {
    width: 100%;
    height: auto;
  }
}

iframe.yt {
  max-width: 560px;
  width: 100%;
}

.isSmallScreen {
  display: block;
    @media (#{$bp-larger-than-phablet}) {
      display: none;
  }
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;
  @include transition(all 300ms);
  &:hover {
    color: $secondary-color;
  }
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $secondary-color;
}
