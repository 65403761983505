/* =============================================================================
   header.scss - Header Styles
   ========================================================================== */

header {
	position: relative;
	display: block;
	width: 100%;
	height: 4rem;
	@include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
	z-index: 1;
	.logo {
		display: block;
		margin-left: 1rem;
		margin-top: 1.3rem;
		font-size: 1.4rem;
		line-height: 1.4rem;
		color: $secondary-color;
		text-transform: uppercase;
		a {
			text-decoration: none;
			color: $secondary-color;
			span {
				color: $third-color;
			}
		}
		span {
		  color: $third-color;
		}
		img {
			width: 100%;
			max-width: 180px;
			height: auto;
		}
	}
}

@media (#{$bp-larger-than-tablet}) {
	header {
		display: none;
	}
}
