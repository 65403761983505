/* ==========================================================================
   content.scss - Content Styles
   ========================================================================== */

$el : "block";

.#{$el} {
    position: relative;
    padding: 0 1rem;

    &-partner {
        background: $white;
        border-radius: 1.5rem;
        text-align: center;
        padding: 2rem 0 1.5rem 0;
        width: 60%;
        margin: 3rem 0 0 4rem;
        img {
            width: 70%;
            height: auto;
        }
    }

    &-headline {
        background: $secondary-color;
        padding: 4rem 2rem;
        margin-bottom: 6rem;
        display: block;
        h1, h2, h3,
        p, a, i {
            color: $white;
        }
        img {
            margin-bottom: 3rem;
        }
        .button {
            background: $white;
            color: $third-color;
            &:hover,
            &:focus {
                color: #fff;
                background-color: $third-color;
            }
        }
        &.background {
            background: url('/img/headline-bg-big-mobile.jpg') no-repeat center top;
        }
        &.orange {
            background: $third-color;
            .button {
                background: $white;
                color: $secondary-color;
                &:hover,
                &:focus {
                    color: #fff;
                    background-color: $secondary-color;
                }
            }
        }
        &-link {
            margin-top: 3rem;
        }
        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1px;
            height: 100%;
            background: $white;
        }
        &:before {
            left: 1rem;
        }
    }

    &-text {
        margin-bottom: 5rem;
        h2, h3 {
            font-weight: bold;
            margin-top: 3rem;
        }
        &-inner {
            h2, h3 {
                margin-top: 3rem;
            }
            h1, h2, h3 {
                font-weight: bold;
            }
        }
        span.question {
            display: inline-block;
            margin-bottom: 5rem;
        }
        #result {
            #bar {
                margin-top: 2rem;
            }
        }
    }

    &-cards {
        margin-bottom: 5rem;
        &-items {
            margin-bottom: 3rem;
        }
        &-item {
            position: relative;
            display: block;
            background: $secondary-color;
            padding: 2rem;
            margin-bottom: 1.6rem;
            @media (#{$bp-larger-than-tablet}) {
                height: 27rem;
            }
            @media (#{$bp-larger-than-desktophd}) {
                height: 29rem;
            }
            a:hover {
				color: $white;
			}
            .card-headline {
                font-size: 1.8rem;
                font-weight: 600;
                font-family: $font-family;
                line-height: 2.4rem;
                color: $white;
                margin-bottom: 0.6rem;
                margin-top: 2rem;
            }
            .card-text {
                font-size: 1.3rem;
                margin-bottom: 2rem;
                @media (#{$bp-larger-than-desktophd}) {
                    font-size: 1.6rem;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-slang {
        display: inline-block;
        h2 {
            margin-bottom: 1.6rem;
            margin-top: 0;
        }
        &-category {
            font-size: 1.5rem;
            line-height: 2rem;
            margin-bottom: 2rem;
        }
        ul.drugs {
            &__category {
                display: inline-block;
                li {
                    display: inline-block;
                    margin-right: 0.4rem;
                    margin-bottom: 0;
                    h2 {
                        margin-bottom: 0;
                        font-size: 1.5rem;
                        line-height: 2rem;
                    }
                }
            }
            &__slang {
                -webkit-column-count: 3;
                -moz-column-count: 3;
                column-count: 3;
                li {
                    h2 {
                        margin-top: 0;
                        margin-bottom: 0.4rem;
                        line-height: 1.8rem;
                        font-size: 1.3rem;
                    }
                }
            }
            &__listslang {
                display: inline-block;
                margin: 0;
                li {
                    float: left;
                    margin-right: 1rem;
                    margin-bottom: 0.5rem;
                    font-size: 1.4rem;
                }
            }
        }
        &-items {
            margin-bottom: 5rem;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        &-col {
            width: 70%;
            float: left;
            margin-right: 30%;    
            margin-bottom: 1.6rem;
        }
        &-item {
            position: relative;
            display: inline-block;
            width: 100%;
            font-size: 1.3rem;
            line-height: 1.8rem;
            a {
                position: relative;
                left: 0;
                &:hover {
                    left: -2px;
                }
            }
        }
        .block-articles-teaser {
            &--inner {
                overflow: hidden;
            }
        }
    }

    &-centers {
        .block-articles-teaser {
            padding: 2.5rem !important;
            h2 {
                margin-top: 2rem;
            }
            @media (#{$bp-larger-than-phablet}) {
                height: 34rem !important;
            }
            @media (#{$bp-larger-than-desktophd}) {
                p, a {
                    font-size: 1.5rem;
                }
            }
        }
    }

    &-search {
        padding: 2rem 0 0.5rem 0;
        &-input {
            float: left;
            margin-right: 1.5rem;
            input {
                height: 42px;
            }
        }
    }

    &-links {
        display: block;
        margin-bottom: 5rem;
    }

    &-articles {
        margin-bottom: 5rem;
        &-items {
            margin-bottom: 1.4rem;
        }
        &-teaser {
            position: relative;
            display: block;
            margin-bottom: 1.6rem;
            @include box-shadow;
            @include transition(all 300ms);
            &-image {
                position: relative;
                display: block;
                overflow: hidden;
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    @include transition(all 3s);
                    @include transform((scale(1) translateZ(0)));
                }
            }
            &-text {
                padding: 1.3rem 1rem;
                .teaser-headline {
                    font-size: 1.3rem;
                    font-family: $font-family;
                    line-height: 1.8rem;
                    margin-bottom: 1rem;
                }
                p, 
                .teaser-perex {
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                    font-weight: 300;
                    margin-bottom: 0;
                }
            }
            &:hover {
                @include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
                @include transition(all 300ms);
                img {
                    display: block;
                    @include transform((scale(1.1) translateZ(0)));
                }
            }
        }
        &-link {
            margin: 3rem 0 2rem 0;
        }
    }

    &-questions {
        margin-bottom: 5rem;
        &-items {
            margin-bottom: 1.4rem;
        }
        &-item {
            position: relative;
            display: block;
            bottom: 0;
            margin-bottom: 1.6rem;
            @include box-shadow;
            @include transition(all 300ms);
            &-text {
                padding: 1.3rem 1rem;
                p {
                    font-size: 1.3rem;
                    line-height: 1.8rem;
                    margin-bottom: 1.3rem;
                    &.question-headline {
                        margin-bottom: 0;
                    }
                }
            }
            &:hover {
                bottom: 4px;
                @include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
                @include transition(all 450ms);
                @include transform(translateZ(0));
            }
        }
        &-link {
            display: inline-block;
            width: 100%;
        }
    }

    &-consults {
        margin-bottom: 5rem;
        &-items {
            margin-bottom: 1.4rem;
            width: 100%;
            float: left;
        }
        &-item {
            position: relative;
            display: inline-block; 
            margin-bottom: 2.5rem;
            &-question {
                padding: 3rem 2.5rem;
                background: $secondary-color;
            }
            &-answer {
                margin-left: 5%;
                float: right;
                padding: 3rem 2.5rem;
                background: $third-color;
            }
            p {
                margin-bottom: 0;
                color: $white;
                &.headline {
                    margin-bottom: 1.5rem;
                }
                a {
                    color: $white;
                    text-decoration: underline;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
        &-ask {
            .form-group {
                width: 48%;
                margin: 0 2% 0 0;
                float: left;
                input {
                    width: 100%;
                }
                &:first-of-type,
                &:nth-of-type(4) {
                    width: 100%;
                    margin: 0;
                }
                &:nth-of-type(3) {
                    margin: 0 0 0 2%;
                }
                &:last-of-type {
                    display: none;
                }
            }
            &--btn {
                display: inline-block;
                width: 100%;
                text-align: center;
                padding: 1.5rem 0 1rem 0;
            }
        }
    }

    &-kontakt {
        &-form {
            margin-top: 3rem;
            .form-group {
                width: 48%;
                margin: 0 2% 0 0;
                float: left;
                input, textarea {
                    width: 100%;
                }
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    width: 100%;
                    margin: 0;
                }
                &:nth-of-type(2) {
                    margin: 0 0 0 2%;
                }
            }
            &--btn {
                display: inline-block;
                width: 100%;
                text-align: center;
                padding: 1.5rem 0 1rem 0;
            }
        }
    }

    &-competition {
        .form-group {
            width: 48%;
            margin: 0 2% 0 0;
            float: left;
            input {
                width: 100%;
                &[type=checkbox]{
                    margin: 0;
                    width: 2rem;
                }
            }
            &:last-of-type {
                width: 100%;
                margin: 0;
            }
            &:nth-of-type(2n) {
                margin: 0 0 0 2%;
            }
        }
        .h2 {
            margin-top: 2rem;
        }
        &--btn {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 1.5rem 0 1rem 0;
        }
    }

    &-infodoctor {
        display: none !important;
        width: 50%;
        margin: 0 auto 5rem auto;
        &-image {
            width: 7rem;
            height: 7rem;
            overflow: hidden;
            border-radius: 50%;
            float: left;
            margin-right: 2.5rem;
            margin-bottom: 4.5rem;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        &-text {
            p {
                &.headline {
                    line-height: 2rem;
                }
            }
        }
    }

    &-survery {
        width: 295px;
        margin: 0 auto;
        #result {
            padding: 2.5rem 3rem;
            background: $third-color;
        }
        #bad {
            text-align: center;
        }
        form {
            margin-bottom: 0;
        }
        &-wrap {
            width: 100%;
        }
        &-swiper {
            height: 450px;
            padding-top: 5rem;
        }
        .swiper-container {
            width: 100%;
            height: 90%;
            margin-top: 5%;
        }
        .swiper-slide {
            font-size: 18px;
            background: #fff;

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            > .row {
                width: 100%;
                text-align: center;
            }
            input {
                display: none;
            }
            label {
                width: 39%;
                margin: .5rem 5%;
                display: inline-block;
                height: 42px;
                min-width: 200px;
                padding: 0 30px;
                text-align: center;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 42px;
                letter-spacing: .1rem;
                text-transform: uppercase;
                text-decoration: none;
                white-space: nowrap;
                cursor: pointer;
                box-sizing: border-box;
                border: 0;
                background: $white;
                @include box-shadow;
                @include transition(all 300ms);
                &:hover,
                &:focus {
                  color: $secondary-color;
                  @include box-shadow(0 2px 6px 0 rgba(0,0,0,0.36));
                  @include transition(all 300ms);
                  outline: 0;
                }
            }
        }
        .swiper-pagination {
            top: 0;
            bottom: auto;
        }
        .swiper-pagination-bullet {
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            opacity: 1;
            background: $white;
            border: 1px solid $secondary-color;
            margin: 0 3px !important;
            span {
                opacity: 0;
                font-size: 2.2rem;
                color: $white;
            }
        }
        .swiper-pagination-bullet-active {
            border: 1.4rem solid $secondary-color;
            background: $secondary-color;
            span {
                left: -0.8rem;
                position: relative;
                opacity: 1;
                width: 2.5rem;
                height: 1rem;
                display: block;
            }
        }
        .swiper-btn-next,
        .swiper-btn-prev {
            display: none !important;
            position: absolute;
            bottom: -1rem;
            left: 4.8rem;
            z-index: 1;
            color: $white;
            background-color: $primary-color;
            &:hover,
            &:focus {
              color: $primary-color;
              background-color: $third-color;
            }
        }
        .swiper-btn-next {
            right: 4.8rem;
            bottom: 4rem;
            left: auto;
            background-color: $secondary-color;
            &:hover,
            &:focus {
              color: $primary-color;
              background-color: $third-color;
            }
        }
    }

    @media (#{$bp-larger-than-tablet}) {
        &-survery {
            width: 550px;
            #bad {
                text-align: left;
                margin-left: 1rem;
            }
            &-swiper {
                height: 380px;
            }
            .swiper-container {
                height: 85%;
            }
            .swiper-btn-next,
            .swiper-btn-prev {
                left: 0;
            }
            .swiper-btn-next {
                right: 0;
                left: auto;
                bottom: -1rem;
            }
        }

        &-headline {
            margin-top: 0;
            &.background {
                background: url('/img/headline-bg-big.jpg') no-repeat center top;
                background-size: cover;
            }
        }
    }

    @media (#{$bp-larger-than-desktop}) {

        padding: 0 4rem;

        &-headline {
            padding: 4rem 18rem;
            &:after {
                right: 4rem;
            }
            &:before {
                left: 4rem;
            }
        }

        &-links {
            max-width: 900px;
            margin: 0 auto 5rem;
        }

        &-questions {
            &-item {
                &-text {
                    p {
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                    }
                }
            }
        }
    }
}

.newsletter-form {
    margin-bottom: 0;
    h2 {
        margin-bottom: 1.3rem;
    }
    p {
        margin-bottom: 2.5rem;
    }
    &-label {
        max-width: 270px;
        width: 100%;
        text-align: left;
        margin: 0 auto;
        color: $white;
        font {
            color: $white !important;
        }
    }
    .small {
        &.text {
            line-height: 1.3;
        }
    }
    input {
        max-width: 270px;
        width: 100%;
        border: 1px solid $white;
        margin-bottom: 0;
        &.button {
            max-width: 200px;
            color: $third-color !important;
            margin-bottom: 3rem;
            &:hover {
                background: $third-color !important;
                border-color: $third-color !important;
                color: $white !important;
            }
        }
    }
    @media (#{$bp-larger-than-phablet}) {
        margin-bottom: 6rem;
    }
}
