/* =============================================================================
   typography.scss - Typography Classes
   ========================================================================== */

h1, h2, h3, h4, h5, h6, .h2, .h3{
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
  font-family: $font-headline;
}

h1 { font-size: 1.9rem; line-height: 2.2rem; letter-spacing: 0; }
h2, .h2 { font-size: 1.9rem; line-height: 2.2rem; letter-spacing: 0; }
h3, .h3 { font-size: 1.9rem; line-height: 2.2rem; letter-spacing: 0; }
h4 { font-size: 1.7rem; line-height: 2.0rem; letter-spacing: 0; }
h5 { font-size: 1.5rem; line-height: 1.6rem; letter-spacing: 0; }
h6 { font-size: 1.5rem; line-height: 1.6rem; letter-spacing: 0; }

// Larger than desktop
@media (#{$bp-larger-than-desktop}) {
  h1 { font-size: 2.6rem; line-height: 3.0rem; }
  h2, .h2 { font-size: 1.9rem; line-height: 2.2rem; }
  h3, .h3 { font-size: 1.9rem; line-height: 2.2rem; }
  h4 { font-size: 1.8rem; line-height: 2.0rem; }
  h5 { font-size: 1.7rem; line-height: 1.8rem; }
  h6 { font-size: 1.7rem; line-height: 1.8rem; }
}

p {
  margin-top: 0;
}

.small {
	font-size: 1.2rem;
}
