html.mm-opening .mm-slideout {
	box-shadow: inset 0 -10px 20px 2px rgba(51,51,51,.2);
}

.mm-menu {
	background: $primary-color;
	color: $white;
}

/*
.mm-menu .mm-listview > li .mm-arrow:after, .mm-menu .mm-listview > li .mm-next:after {
	@include transition(border-color 300ms);
	border-color: $white;
}
.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), .mm-menu .mm-listview>li.mm-selected>span {
	background: $secondary-color;
}
.mm-menu .mm-listview {
	border-color: $white;
	padding: 10px 25px;
}
.mm-listview .mm-next {
	background: $primary-color;
}
.mm-listview .mm-next:before {
	background: $white;
	border-left-width: 3px;
}
.mm-listview > li {
	margin-bottom: 5px;
	float: none;
}
.mm-listview > li:not(.mm-divider):after {
	border: none;
}
.mm-listview > li > a, .mm-listview > li > span {
	background: $primary-color;
	color: $white !important;
	font-weight: 700;
}
.mm-listview > li:hover,
.mm-listview > li.active {
	> a {
		color: $white !important;
		&.mm-next:after {
			border-color: $white !important;
		}
	}
	> span {
		color: $primary-color !important;
		&.mm-next:after {
			border-color: $white !important;
		}
	}
}
.mm-navbar {
	padding: 10px 25px;
	border-bottom: 0;
}
.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
	background: $primary-color;
	color: $white;
	font-weight: 700;
}
.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}
.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
	border-color: $white;
}
.mm-navbar .mm-btn:first-child {
	left: 25px;
	top: 11px;
}
*/
