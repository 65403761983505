/* ==========================================================================
   Main stylesheet. Use this file only to include other files
   !!!IMPORTANT!!!: do not leave comments around @import, but remove the imports
   ========================================================================== */
   
/* Base files. */
@import "base/_normalize";
@import "base/_variables";
@import "base/_functions";
@import "base/_base-styles";
@import 'base/_helpers';
@import "base/_fonts";
@import "base/_typography";

/* Modules */
@import "modules/_grid";
@import "modules/_buttons";
@import "modules/_forms";
@import "modules/_lists";
@import "modules/_tables";
@import "modules/_spacing";
//@import "modules/_media-queries";

/* Styling */
@import "page/_header.scss";
@import "page/_navigation.scss";
@import "page/_footer";
@import "page/_content.scss";
@import "page/_home.scss";
@import "page/_news.scss";

@import "vendor/_custom-jquery-mmenu-all.scss";