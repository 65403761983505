/* =============================================================================
   footer.scss - Footer Styles
   ========================================================================== */

.footer {
	display: block;
	min-height: 2rem;
	text-align: center;
	color: $white;
	font-size: 1.3rem;
	line-height: 2.5rem;
	padding: 3rem 0.5rem;
	background: $primary-color;
	a {
		color: $white;
		&:hover {
			color: $secondary-color;
		}
	}

	.copy {
		width: 100%;
		display: inline-block;
		margin-top: 2rem;
	}

	ul {
		display: block;
		list-style: none;
		padding: 0;
		margin: 0 0 0.75rem 0;
		li {
			width: 100%;
			display: inline-block;
			line-height: 1.5;
			margin-bottom: 1.5rem;
			h3 {
				margin-bottom: 1rem;
				a {
					font-size: 1.6rem;
					font-weight: 600;
				}
			}
			a {
				font-size: 1.3rem;
				text-decoration: none;				
				margin: 0 0.5rem;
			}
			ul {
				li {
					width: auto;
					margin-bottom: 0.35rem;
				}
			}
		}
	}
}

@media (#{$bp-larger-than-tablet}) {
	.footer {
		background: $secondary-color;
		padding: 3rem 5rem 2rem;
		a {
			color: $white;
			&:hover {
				color: $primary-color;
			}
		}

		ul {
			li {
				position: relative;
				float: left;
				width: 18%;
				margin: 0 1% 2.5rem 1%;
				text-align: left;
				a {
					margin: 0;
				}
				ul {
					li {
						width: 100%;
						margin: 0;
						a {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media (#{$bp-larger-than-desktop}) {
	.footer {
		ul {
			width: 80%;
			margin: 0 auto .75rem;
			 li {
				ul {
					width: 100%;
				}
			}
		}
	}
}